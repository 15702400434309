.leaflet-marker-icon-animated {
  transition: transform 1s, opacity 1s;
}

.leaflet-container {
  background: #f9f9f9;
  position: absolute;
  inset: 0;
  font-family: "system-ui", Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.leaflet-container img, .leaflet-container img.leaflet-image-layer {
  max-width: none !important;
}

.leaflet-container img.asset, img.crosshair {
  pointer-events: none;
  cursor: none;
}

.leaflet-fade-anim .leaflet-popup {
  transition: none;
}

.leaflet-popup-scrolled {
  border: none;
}

.leaflet-popup {
  margin-bottom: 15px;
}

.leaflet-popup-content {
  margin: 10px;
}

.leaflet-popup-map .leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-content-wrapper {
  border-radius: 4px;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  box-shadow: 0 3px 14px #0000000d;
}

.leaflet-popup-content-wrapper ul, .leaflet-popup-tip ul {
  max-height: 154px;
  margin: 0 -10px 0 0;
  padding-left: 20px;
  overflow: scroll;
}

.leaflet-label ul {
  list-style: none;
}

a.leaflet-popup-close-button {
  display: none;
}

.leaflet-div-icon {
  background: none;
  border: 0;
}

.loading-dots {
  width: 13px;
  height: 3px;
  display: flex;
  position: relative;
}

.loading-dots .dot {
  background: #fff;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  animation: 1.4s infinite blink;
  display: flex;
  position: absolute;
}

.loading-dots .dot-1 {
  animation-delay: .2s;
  left: 0;
}

.loading-dots .dot-2 {
  animation-delay: .4s;
  left: 5px;
}

.loading-dots .dot-3 {
  animation-delay: .6s;
  left: 10px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.leaflet-vertex-icon {
  z-index: 9999 !important;
  background: #fff !important;
  border: 1px solid #00aae4 !important;
  border-radius: 50% !important;
  width: 15px !important;
  height: 15px !important;
}

.leaflet-middle-icon {
  z-index: 9999 !important;
  background: #fff !important;
  border: 1px solid #00aae4 !important;
  border-radius: 50% !important;
  width: 10px !important;
  height: 10px !important;
  margin-top: -7px !important;
  margin-left: -7px !important;
}

.leaflet-vertex-icon:hover, .leaflet-middle-icon:hover {
  background: #00aae4 !important;
}

.leaflet-control-zoom {
  width: 37px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  width: auto !important;
}

.leaflet-area-targeted {
  cursor: crosshair;
}

.leaflet-tooltip.tooltip-label {
  box-shadow: none;
  color: #fff;
  letter-spacing: .025em;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  z-index: 6;
  background-color: #00aae4;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  padding: 3px 6px;
  font: 9px / 12px "system-ui", Helvetica Neue, Helvetica, Arial, sans-serif;
  display: block;
  position: absolute;
}

.leaflet-tooltip.tooltip-label:before {
  content: none;
}

.leaflet-tooltip.tooltip-label ul {
  margin: 0;
  padding: 0;
}
/*# sourceMappingURL=index.d9a823f4.css.map */
