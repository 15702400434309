/* transition markers */
.leaflet-marker-icon-animated {
  transition: transform 1s, opacity 1s;
}

.leaflet-container {
  background: #f9f9f9;
  font-family: "system-ui", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.leaflet-container img {
  max-width: none !important;
}

.leaflet-container img.leaflet-image-layer {
  max-width: none !important;
}

.leaflet-container img.asset, img.crosshair {
  pointer-events: none;
  cursor: none;
}

.leaflet-fade-anim .leaflet-popup {
  transition: none;
}

.leaflet-popup-scrolled {
  border: none;
}

.leaflet-popup {
  margin-bottom: 15px;
}

.leaflet-popup-content {
  margin: 10px;
}

.leaflet-popup-map .leaflet-popup-content {
  margin: 0px;
}

.leaflet-popup-content-wrapper {
  border-radius: 4px;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  box-shadow: 0 3px 14px rgba(0, 0, 0, .05);
}

.leaflet-popup-content-wrapper ul, .leaflet-popup-tip ul {
  padding-left: 20px;
  margin: 0;
  margin-right: -10px;
  max-height: 154px;
  overflow: scroll;
}

.leaflet-label ul {
  list-style: none;
}

a.leaflet-popup-close-button {
  display: none;
}

/* Reset DivIcon for map labels */
.leaflet-div-icon {
  background: none;
  border: 0;
}

.loading-dots {
  display: flex;
  position: relative;
  width: 13px;
  height: 3px;
}

.loading-dots .dot {
  position: absolute;
  display: flex;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #FFF;
  animation: blink 1.4s infinite;
}

.loading-dots .dot-1 {
  left: 0px;
  animation-delay: 0.2s;
}

.loading-dots .dot-2 {
  left: 5px;
  animation-delay: 0.4s;
}

.loading-dots .dot-3 {
  left: 10px;
  animation-delay: 0.6s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.leaflet-vertex-icon {
  background: #FFF !important;
  border-radius: 50% !important;
  border: 1px solid #00AAE4 !important;
  height: 15px !important;
  width: 15px !important;
  z-index: 9999 !important;
}

.leaflet-middle-icon {
  background: #FFF !important;
  border-radius: 50% !important;
  border: 1px solid #00AAE4 !important;
  margin-left: -7px !important;
  margin-top: -7px !important;
  height: 10px !important;
  width: 10px !important;
  z-index: 9999 !important;
}

.leaflet-vertex-icon:hover, .leaflet-middle-icon:hover  {
  background: #00AAE4 !important;
}

/* Remove this once we implement custom zoom control */
.leaflet-control-zoom {
  width: 37px;
}

.leaflet-touch .leaflet-control-zoom-in {
  width: auto !important;
}

.leaflet-touch  .leaflet-control-zoom-out {
  width: auto !important;
}

/* Applied when an area is to be targeted for accurate point */
.leaflet-area-targeted {
    cursor: crosshair;
}

/* legacy label/tooltip styles - zones */
.leaflet-tooltip.tooltip-label {
    background-color: #00AAE4;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: none;
    color: #fff;
    display: block;
    font: 9px/12px "system-ui", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: .025em;
    padding: 3px 6px;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    white-space: nowrap;
    z-index: 6;
}
.leaflet-tooltip.tooltip-label:before {
    content: none;
}

.leaflet-tooltip.tooltip-label ul {
    margin: 0;
    padding: 0;
}
